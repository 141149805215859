import React, { useState,useEffect } from 'react';

import Lottie from 'react-lottie';
import * as scrollBtn from '../../src/json/scroll.json';
import { MdPlayArrow } from "react-icons/md";

import { animateScroll as scroll } from 'react-scroll';
import Tilt from 'react-parallax-tilt';

import ReactPlayer from 'react-player';




const HomeScreen = () => {


  const [isPlaying, setIsPlaying] = useState(true); // Initialize to autoplay the video

  const handleTogglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const images = [
    "https://api.listudiosl.com/wp-content/uploads/2024/05/Mow.png",
    "https://api.listudiosl.com/wp-content/uploads/2024/06/G98.png"
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      setTimeout(() => setIsAnimating(false), 300); 
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [images.length]);
 


const ScrollBtnFunction = {
    loop: true,
    autoplay: true, 
    animationData: scrollBtn,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleScroll = () => {
    const currentScrollPosition = window.pageYOffset;
    const scrollToPosition = currentScrollPosition + 1000; // 10px from the current position
    scroll.scrollTo(scrollToPosition, {
      duration: 1000,
      smooth: 'easeInOutQuart'
    });
  };

  
  return (
    <div className="HomeScreen">
     

      <div className="hoem-intro-banner">
      <div className="container-fluid">
       <div className="row">
        <div className="col intro-col p-0">
          <div className="intro-banner-img">
         
         
              <div className="video-player">
              <ReactPlayer
          url='https://api.listudiosl.com/wp-content/uploads/2024/07/Li.mp4'
          playing={isPlaying}
          loop
          controls={false}
          className='intro-banner'
          width='100%'
          height='100vh'
          
        />
              </div>
         
          <div className="intro-text">
            <h1 className='intro-h1 roboto-light white text-center'>we build <span className='intro-span roboto-black white'>brands</span></h1>
            <div className="intro-btn">
                <button className='roboto-medium header-text-style white pushable'  onClick={handleTogglePlay}>
                <span className="shadow"></span>
                <span className="edge"></span>
               <span className="front header-text-style"><MdPlayArrow size={30} /> {isPlaying ? 'Pause story' : 'Play story'}</span>
               </button>
                </div>
          </div>
         
          </div>
          
        </div>
       </div>
       <div className="row">
        <div className="col-md-12">
          <div className="scroll-btn" onClick={handleScroll}>
          <Lottie options={ScrollBtnFunction} height={40} width={115}/>
          </div>
        </div>
       </div>
       </div>

      </div>


        
      <div className="backgroud-section-home">
      <div className="brand-section">
        <div className="container-fluid section-gap">
          <div className="row gap-row">
            <div className="col-md-12 brand-col">
              <div className="brand-text-box">
                <h2 className='roboto-light white'>We served <span className='red roboto-black'>200+ brands</span> globally, aiding them in reaching goals and expanding businesses.</h2>
                <div className="explore-btn">
                <button className='roboto-medium header-text-style white pushable'>
                <span className="shadow"></span>
                <span className="edge"></span>
               <span className="front header-text-style">Explore about us</span>
               </button>
                </div>
              </div>    
            </div>
          </div>
      
          <div className="row">
          
            <div className="Case-study-one col-md-6">
            <Tilt  tiltMaxAngleX={2} tiltMaxAngleY={2}>
              <div className="case-study-one-animation">
            
                <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/Case-study-card-1.jpg" alt='case-study-one' className='case-study-one-image border-redus-img' width='100%' loading="lazy" />
            
                <div className="case-study-text-smoll">
                <p className='b23 roboto-light white'>Case study name</p>
                </div>
                <div className="text-animation-box">
                   <p className='b1 roboto-light white'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
                </div>
                <div className="btn-animation-box">
                    <button className='btn-animation'>View case study</button>
                </div>

              </div>
              </Tilt>
              
              

            </div>
            
            <div className="Case-study-two col-md-6">
            <Tilt  tiltMaxAngleX={2} tiltMaxAngleY={2}>
               <div className="case-study-two-animation">
                <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/Case-study-card-2.jpg" alt='case-study-two' className='case-study-two-image border-redus-img' width='100%' loading="lazy" />
                <div className="case-study-text-smoll">
                <p className='b23 roboto-light white'>Case study name</p>
                </div>
                <div className="text-animation-box">
                   <p className='b1 roboto-light white'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
                </div>
                <div className="btn-animation-box">
                    <button className='btn-animation'>View case study</button>
                </div>
              </div>
              </Tilt>
            </div>
          </div>

          <div className="row gap-row">
            <div className="Case-study-three col-md-6">
            <Tilt  tiltMaxAngleX={2} tiltMaxAngleY={2}>
              <div className="case-study-three-animation">
                <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/Case-study-card-3.jpg" alt='case-study-three' className='case-study-three-image border-redus-img' width='100%' />
                <div className="case-study-text-smoll">
                <p className='b23 roboto-light white'>Case study name</p>
                </div>
                <div className="text-animation-box">
                   <p className='b1 roboto-light white'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
                </div>
                <div className="btn-animation-box">
                    <button className='btn-animation'>View case study</button>
                </div>
              </div>
              </Tilt>

            </div>

            <div className="Case-study-four col-md-6">
            <Tilt  tiltMaxAngleX={2} tiltMaxAngleY={2}>
              <div className="case-study-four-animation">
                <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/Case-study-card-4.jpg" alt='case-study-four' className='case-study-four-image border-redus-img' width='100%' loading="lazy" />
                <div className="case-study-text-smoll">
                <p className='b23 roboto-light white'>Case study name</p>
                </div>
                <div className="text-animation-box">
                   <p className='b1 roboto-light white'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
                </div>
                <div className="btn-animation-box">
                    <button className='btn-animation'>View case study</button>
                </div>
                
              </div>
            </Tilt>
            </div>

          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="case-study-btn">  
                <button className='roboto-medium header-text-style white pushable'>
                <span className="shadow"></span>
                <span className="edge"></span>
               <span className="front header-text-style">View more case studies</span>
               </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="behance-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 border-hand p-0">
               <div className="hand-animation">
               <Tilt  tiltMaxAngleX={20} tiltMaxAngleY={20}>
                  <img src='https://api.listudiosl.com/wp-content/uploads/2024/05/hand-2.png' alt='hand-animation' className='hand-animation' width='571' height='571' loading="lazy" />
              </Tilt>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6 behance-border p-0">
                   <div className="behance-text-box">
                    <h3 className='roboto-light white'>For more work, follow us on</h3>
                    <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/Behance_logo-white-1.png" alt="" width='150px' loading="lazy" />
                   </div>
                </div>
                <div className="col-md-6 behance-border p-0">
                  <div className="row m-0">
                    <div className="col-md-6 p-0">
                        <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/Behance-1.jpg" alt="" width='100%' loading="lazy" />
                    </div>
                    <div className="col-md-6 p-0">
                      <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/Behance-2.jpg" alt="" width='100%' loading="lazy" />
                    </div>
                    <div className="col-md-6 p-0">
                       <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/Behance-3.jpg" alt="" width='100%' loading="lazy" />
                    </div>
                    <div className="col-md-6 p-0">
                      <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/Behance-4.jpg" alt="" width='100%' loading="lazy" />
                    </div>
                  </div>

                </div>
                <div className="col-md-6 mov-border p-0">
                  <div className="mow--box">
                    <p className='b1 roboto-light Tone-Down'>Our Affiliated Companies</p>
                    <img
                      src={images[currentImageIndex]}
                       alt={`Slide ${currentImageIndex}`}
                      width='100%'
                        className={`mow-image ${isAnimating ? 'jump' : ''}`}
                     />
                  </div>
                </div>
                <div className="col-md-6 join-us p-0">
                  <div className="join-us-box">
                    <h3 className='roboto-light white'>Join our team, check <span className='roboto-medium'>open positions</span></h3>
                     <div className="join-us-btn">
                     <button className='roboto-medium header-text-style white pushable'>
                       <span className="shadow"></span>
                       <span className="edge"></span>
                       <span className="front header-text-style">Join us</span>
                    </button>
                     </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-running-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 p-0">
            <div className="qodef-m-content">
             <div className='qodef-m-content-inner running-text'>
                 <span className='roboto-light Tone-Down Tone-Down-Text '>
                    We are celebrating 16th year anniversary on March 1st. 
                 </span>
                 <span className='roboto-light Tone-Down Tone-Down-Text '>
                          LI “Awurudu” 2024 coming soon!. 
                 </span>
                 <span className='roboto-light Tone-Down Tone-Down-Text '>
                    Welcome “James” our new team member.
                 </span>
              </div>

              <div className='qodef-m-content-inner running-text'>
                 <span className='roboto-light Tone-Down Tone-Down-Text '>
                    We are celebrating 16th year anniversary on March 1st. 
                 </span>
                 <span className='roboto-light Tone-Down Tone-Down-Text '>
                          LI “Awurudu” 2024 coming soon!. 
                 </span>
                 <span className='roboto-light Tone-Down Tone-Down-Text '>
                    Welcome “James” our new team member.
                 </span>
              </div>

              <div className='qodef-m-content-inner running-text'>
                 <span className='roboto-light white Tone-Down-Text '>
                    We are celebrating 16th year anniversary on March 1st. 
                 </span>
                 <span className='roboto-light white Tone-Down-Text '>
                          LI “Awurudu” 2024 coming soon!. 
                 </span>
                 <span className='roboto-light white Tone-Down-Text '>
                    Welcome “James” our new team member.
                 </span>
              </div>
     
       
      
    </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      

      <div className="clients-section">
        <div className="row backgroud-chnage">
         <div className="upper-blue-square"></div>
         <div className="lower-blue-square"></div>
         <div className="purple-square"></div>
        <div className="blue-square"></div>
         </div>
        <div className="container-fluid section-gap-footer">
         
          <div className="row gap-row ">
            <div className="col-md-4 client-text-col">
              <div className="client-text">
                <h2 className='roboto-light'>Meet our <span className='roboto-black'>clients</span></h2>
                <p className='roboto-light'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
              </div>
            </div>
            <div className="col-md-8 client-log-col">
              <div className="row">
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image-1.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>
                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image-2.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image-3.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image-4.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image-5.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image-6.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image-7.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image-8.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image-9.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image-10.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image-11.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image-12.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image-13.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image-14.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image-15.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image-16.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image-17.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image-18.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image-19.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>

                </div>
                <div className="col-2.4">
                <div className="client-img">
                 <img src="https://api.listudiosl.com/wp-content/uploads/2024/05/li-client-image.png" alt='client-img' className='client-img' width='100%' loading="lazy" />
                </div>

                </div>
              </div>

            </div>
          </div>
         
        </div>
      </div>
    </div>
  )
}

export default HomeScreen