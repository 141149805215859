import React from 'react'
import ReactPlayer from 'react-player';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";

const LifeLIScreen = () => {
return (
<div className="LifeLIScreen">
   <div className="LifeLibanner">
      <div className="container-fuild">
         <div className="row LifeLiBanner-row">
            <div className="col-md-6 LifeLiText-col">
               <div className="LifeText-box">
                  <h2 className='roboto-light white'>Life at <span className='roboto-black white '>Limitless Ideation</span></h2>
                  <p className='b1 roboto-light Tone-Down'>At <span className='roboto-black white '>Limitless Ideation</span>, we believe in creating a vibrant and inclusive environment where everyone can thrive. Here’s a glimpse into what life is like at our organization.</p>
               </div>
            </div>
            <div className="col-md-6 LifeLiVideo-col">
               <div className="LifeLiVideo-box">
                  <ReactPlayer
                     url='https://api.listudiosl.com/wp-content/uploads/2024/08/Sequence-02.mp4'
                     loop
                     controls={false}
                     playing={true}
                     muted={true}
                     width='100%'
                     height='auto'
                     loading="lazy"
                     />
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className="our-culture">
      <div className="container-fuild">
         <div className="row cultrue-row">
            <div className="col-md-12">
               <div className="cultrue-box">
                  <h2 className='roboto-light white'>Our <span className='roboto-black white '>Culture</span></h2>
                  <p className='b1 roboto-light Tone-Down'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.dolor sit amet, </p>
               </div>
            </div>
         </div>
         <div className="row row-mesonory">
            <div className="col-md-12">
               <div className="mesonory-grid">
                  <ResponsiveMasonry columnsCountBreakPoints={{ 750: 1, 250: 2, 900: 3}}>
                  <Masonry  gutter="16px" >
                     {/* First Column: Text Box */}
                     <div className='mesonory-text-grid-box-1'>
                        <h3 className='roboto-light white'>Our creativity allows us to imagine new possibilities</h3>
                     </div>
                     {/* Second Column: Image */}
                     <img src="https://api.listudiosl.com/wp-content/uploads/2024/08/Rectangle-17243.png" loading="lazy" alt="" width="100%" height="auto" />
                     {/* Third Column: Text Box */}
                     <div className='mesonory-text-grid-box-2'>
                        <h3 className='roboto-light white'>We owe it to all of our clients.</h3>
                     </div>
                     {/* Second Column: Image */}
                     <img src="https://api.listudiosl.com/wp-content/uploads/2024/08/Rectangle-17245.png" loading="lazy" alt="" width="100%" height="auto"/>
                     {/* Third Column: Text Box */}
                     <div className='mesonory-text-grid-box-3'>
                        <h3 className='roboto-light white'>Our creativity allows us to imagine new possibilities</h3>
                     </div>
                     {/* Second Column: Image */}
                     <img src="https://api.listudiosl.com/wp-content/uploads/2024/08/Rectangle-17246.png" loading="lazy" alt="" width="100%" height="auto" />
                  </Masonry>
                  </ResponsiveMasonry>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className="our-careers">
      <div className="container-fuild">
         <div className="row our-careers-row">
            <div className="col-md-12 our-careers-col">
               <div className="our-carees-text-box">
                  <h2 className='roboto-light white'>Careers at <span className='roboto-black white '>LI</span></h2>
                  <p className='b1 roboto-light Tone-Down'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
               </div>
            </div>
         </div>
         <div className="row">
            <div className="col-md-12 p-0">
               <div className="container-faq">
                  <div className="item">
                     <div className="item-header">
                        <div className="title">
                           <h2 className="roboto-light white">UI/UX Designer</h2>
                        </div>
                     </div>
                     <div className="item-body">
                        <div className='faq-box-btn-text'>
                           <p className="roboto-regular Tone-Down b1">
                              Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                           </p>
                           <button className="roboto-medium header-text-style white pushable"><span className="shadow"></span><span className="edge"></span><span className="front header-text-style">Apply Now</span></button>
                        </div>
                     </div>
                  </div>
                  <div className="item">
                     <div className="item-header">
                        <div className="title">
                           <h2 className="roboto-light white">Graphic Designer</h2>
                        </div>
                     </div>
                     <div className="item-body">
                        <div className='faq-box-btn-text'>
                           <p className="roboto-regular Tone-Down b1">
                              Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                           </p>
                           <button className="roboto-medium header-text-style white pushable"><span className="shadow"></span><span className="edge"></span><span className="front header-text-style">Apply Now</span></button>
                        </div>
                     </div>
                  </div>
                  <div className="item">
                     <div className="item-header">
                        <div className="title">
                           <h2 className="roboto-light white">Software Engineer</h2>
                        </div>
                     </div>
                     <div className="item-body">
                        <div className='faq-box-btn-text'>
                           <p className="roboto-regular Tone-Down b1">
                              Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                           </p>
                           <button className="roboto-medium header-text-style white pushable"><span className="shadow"></span><span className="edge"></span><span className="front header-text-style">Apply Now</span></button>
                        </div>
                     </div>
                  </div>
                  <div className="item">
                     <div className="item-header">
                        <div className="title">
                           <h2 className="roboto-light white">Project Manager</h2>
                        </div>
                     </div>
                     <div className="item-body">
                        <div className='faq-box-btn-text'>
                           <p className="roboto-regular Tone-Down b1">
                              Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                           </p>
                           <button className="roboto-medium header-text-style white pushable"><span className="shadow"></span><span className="edge"></span><span className="front header-text-style">Apply Now</span></button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className="working">
      <div className="container-fuild">
         <div className="row working-row">
            <div className="col-md-5 working-box-col">
               <div className="working-box">
                  <h2 className='roboto-light white'>Our <span className='roboto-black white'>Working Environment</span></h2>
                  <p className='roboto-regular Tone-Down b1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation</p>
               </div>
            </div>
            <div className="col-md-2 working-img-1">
               <div className="working-img">
                  <img src="https://api.listudiosl.com/wp-content/uploads/2024/08/Rectangle-17258.png" loading="lazy" alt="" width="100%" height="450px" />
               </div>
            </div>
            <div className="col-md-3 working-img-2">
               <div className="working-img">
                  <img src="https://api.listudiosl.com/wp-content/uploads/2024/08/Rectangle-17259.png" loading="lazy" alt=""  width="100%" height="450px"/>
               </div>
            </div>
            <div className="col-md-2 working-img-3">
               <div className="working-img">
                  <ReactPlayer
                     url='https://api.listudiosl.com/wp-content/uploads/2024/08/7377732363599564033.mp4'
                     loop
                     controls={false}
                     playing={true}
                     muted={true}
                     width='100%'
                     height='450px'
                     loading="lazy"
                     />
               </div>
            </div>
         </div>
         <div className="row working-row-next">
            <div className="col-md-2 working-img">
               <div className="working-img">
                  <img src="https://api.listudiosl.com/wp-content/uploads/2024/08/Rectangle-17291.png" alt="" width="100%" height="450px" />
               </div>
            </div>
            <div className="col-md-2 working-img">
               <div className="working-img">
               <ReactPlayer
                     url='https://api.listudiosl.com/wp-content/uploads/2024/08/7369943382786870529.mp4'
                     loop
                     controls={false}
                     playing={true}
                     muted={true}
                     width='100%'
                     height='450px'
                     loading="lazy"
                     />
               </div>
            </div>
            <div className="col-md-3 working-img">
               <div className="working-img">
                  <img src="https://api.listudiosl.com/wp-content/uploads/2024/08/Rectangle-17247.png" alt="" width="100%" height="450px" />
               </div>
            </div>
            <div className="col-md-5 working-img">
               <div className="working-img">
                  <img src="https://api.listudiosl.com/wp-content/uploads/2024/08/Rectangle-17264.png" alt="" width="100%" height="450px" />
               </div>
            </div>
         </div>
      </div>
   </div>
   <div className="benifits">
      <div className="container-fuild">
         <div className="row benifts-row">
            <div className="col-md-12 col-text-benifts">
               <div className="benifts-text-box">
                  <h2 className='roboto-light Dark-Blue'>Unlock Exclusive <span className='roboto-black Dark-Blue'>Benefits</span></h2>
               </div>
            </div>
         </div>
         <div className="row benifts-faq-row">
            <div className="col-md-7 benifts-col-faq-text">
               <div className="benifts-faq-text">
                  <h3 className='roboto-light Dark-Blue'>Your Benefits at LI</h3>
                  <p className='roboto-light Dark-Blue b1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                  <p className='roboto-light Dark-Blue b1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
               </div>
            </div>
            <div className="col-md-5 benifts-col-faq">
               <div className="benifts-faq">
                  <details open>
                     <summary className='faq-header'>
                        <h3 className='roboto-light Dark-Blue'>How do I create accordion?</h3>
                     </summary>
                     <div className='faq-body'>
                        <ul className='faq-ul'>
                           <li className='faq-li roboto-light Dark-Blue b1'>Subsidized healthcare for you and your family</li>
                           <li className='faq-li roboto-light Dark-Blue b1'>A safe, ethical, secure, and healthy workplace</li>
                           <li className='faq-li roboto-light Dark-Blue b1'>Mental health and wellbeing support</li>
                        </ul>
                     </div>
                  </details>
                  <details>
                     <summary className='faq-header'>
                        <h3 className='roboto-light Dark-Blue'>Financial Well-Being</h3>
                     </summary>
                     <div className='faq-body'>
                        <ul className='faq-ul'>
                           <li className='faq-li roboto-light Dark-Blue b1'>Subsidized healthcare for you and your family</li>
                           <li className='faq-li roboto-light Dark-Blue b1'>A safe, ethical, secure, and healthy workplace</li>
                           <li className='faq-li roboto-light Dark-Blue b1'>Mental health and wellbeing support</li>
                        </ul>
                     </div>
                  </details>
                  <details>
                     <summary className='faq-header'>
                        <h3 className='roboto-light Dark-Blue'>Personal Development</h3>
                     </summary>
                     <div className='faq-body'>
                        <ul className='faq-ul'>
                           <li className='faq-li roboto-light Dark-Blue b1'>Subsidized healthcare for you and your family</li>
                           <li className='faq-li roboto-light Dark-Blue b1'>A safe, ethical, secure, and healthy workplace</li>
                           <li className='faq-li roboto-light Dark-Blue b1'>Mental health and wellbeing support</li>
                        </ul>
                     </div>
                  </details>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
)
}
export default LifeLIScreen