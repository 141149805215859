import React from 'react';

const PortfolioScreen = () => {
  return (
    
    <div className="PortfolioScreen">
        <div className="portfolio-banner">
            <div className="container-fuild">
                <div className="row">
                    <div className="col-md-12">
                        <div className="intro-portpolio-text">
                            <h2 className='roboto-light white'>We served <span className='roboto-black red '>200+ brands</span> globally, aiding them in reaching goals and expanding businesses.</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="case-study">
            <div className="container-fuild">
                <div className="row">
                    <div className="col-md-6 case-study-row">
                        <div className="case-study-box">
                            <img src="https://api.listudiosl.com/wp-content/uploads/2024/08/Case-study-card-3.png" alt="case-study-name" loading="lazy"/>
                        </div>
                        <div className="case-title">
                             <p className='b23 roboto-light white'>Case study name</p>
                        </div>
                        <div className="case-study-description">
                        <p className='b1 roboto-light white'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
                        </div>
                        <div className="case-study-btn-por">
                            <button className='btn-animation' onClick={() => window.location.href = "/casestudyinner"}>View case study</button>
                        </div>

                    </div>

                    <div className="col-md-6 case-study-row">
                        <div className="case-study-box"> 
                            <img src="https://api.listudiosl.com/wp-content/uploads/2024/08/Case-study-card-2.png" alt="case-study-name" loading="lazy"/>
                        </div>
                        <div className="case-title">
                             <p className='b23 roboto-light white'>Case study name</p>
                        </div>
                        <div className="case-study-description">
                        <p className='b1 roboto-light white'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
                        </div>
                        <div className="case-study-btn-por">
                            <button className='btn-animation'>View case study</button>
                        </div>

                    </div>
                </div>

                <div className="row margin-row">
                    <div className="col-md-6 case-study-row">
                        <div className="case-study-box">
                            <img src="https://api.listudiosl.com/wp-content/uploads/2024/08/Case-study-card-4.png" alt="case-study-name" loading="lazy"/>
                        </div>
                        <div className="case-title">
                             <p className='b23 roboto-light white'>Case study name</p>
                        </div>
                        <div className="case-study-description">
                        <p className='b1 roboto-light white'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
                        </div>
                        <div className="case-study-btn-por">
                            <button className='btn-animation'>View case study</button>
                        </div>

                    </div>

                    <div className="col-md-6 case-study-row">
                        <div className="case-study-box">
                            <img src="https://api.listudiosl.com/wp-content/uploads/2024/08/Case-study-card-1.png" alt="case-study-name" loading="lazy"/>
                        </div>
                        <div className="case-title">
                             <p className='b23 roboto-light white'>Case study name</p>
                        </div>
                        <div className="case-study-description">
                        <p className='b1 roboto-light white'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
                        </div>
                        <div className="case-study-btn-por">
                            <button className='btn-animation'>View case study</button>
                        </div>

                    </div>
                </div>

                <div className="row margin-row">
                    <div className="col-md-6 case-study-row">
                        <div className="case-study-box">
                            <img src="https://api.listudiosl.com/wp-content/uploads/2024/08/Case-study-card-3.png" alt="case-study-name" loading="lazy"/>
                        </div>
                        <div className="case-title">
                             <p className='b23 roboto-light white'>Case study name</p>
                        </div>
                        <div className="case-study-description">
                        <p className='b1 roboto-light white'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
                        </div>
                        <div className="case-study-btn-por">
                            <button className='btn-animation'>View case study</button>
                        </div>

                    </div>

                    <div className="col-md-6 case-study-row">
                        <div className="case-study-box">
                            <img src="https://api.listudiosl.com/wp-content/uploads/2024/08/Case-study-card-2.png" alt="case-study-name" loading="lazy"/>
                        </div>
                        <div className="case-title">
                             <p className='b23 roboto-light white'>Case study name</p>
                        </div>
                        <div className="case-study-description">
                        <p className='b1 roboto-light white'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
                        </div>
                        <div className="case-study-btn-por">
                            <button className='btn-animation'>View case study</button>
                        </div>

                    </div>
                </div>

                <div className="row margin-row">
                    <div className="col-md-6 case-study-row">
                        <div className="case-study-box">
                            <img src="https://api.listudiosl.com/wp-content/uploads/2024/08/Case-study-card-4.png" alt="case-study-name" loading="lazy"/>
                        </div>
                        <div className="case-title">
                             <p className='b23 roboto-light white'>Case study name</p>
                        </div>
                        <div className="case-study-description">
                        <p className='b1 roboto-light white'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
                        </div>
                        <div className="case-study-btn-por">
                            <button className='btn-animation' >View case study</button>
                        </div>

                    </div>

                    <div className="col-md-6 case-study-row">
                        <div className="case-study-box">
                            <img src="https://api.listudiosl.com/wp-content/uploads/2024/08/Case-study-card-1.png" alt="case-study-name" loading="lazy"/>
                        </div>
                        <div className="case-title">
                             <p className='b23 roboto-light white'>Case study name</p>
                        </div>
                        <div className="case-study-description">
                        <p className='b1 roboto-light white'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
                        </div>
                        <div className="case-study-btn-por">
                            <button className='btn-animation'>View case study</button>
                        </div>

                    </div>
                </div>
            </div>
         
        </div>
    </div>


  )
}

export default PortfolioScreen