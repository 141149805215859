import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Pagination, Navigation } from 'swiper/modules';

gsap.registerPlugin(ScrollTrigger);





const AboutScreen = () => {

  useEffect(() => {
    const imageBox = document.querySelector(".image-col");
    const textBox = document.querySelector(".text-col");
  
    gsap.fromTo(
      imageBox,
      {
        scale: 1,
        x: "0%", // Initial position of the image
      },
      {
        scale: 0.5, // Zoom out
        x: "0%", // Move the image slightly towards the left as it zooms out
        y: "0px", // Ensure the image stays on the same vertical plane
        scrollTrigger: {
          trigger: ".scroll-section",
          start: "top 150%", // Start the animation when the top of the image reaches the middle of the viewport
          end: "bottom top", // End when the bottom of the container hits the top of the viewport
          scrub: true, // Smooth animation
        },
        transformOrigin: "left center", // Zoom out from the left side, making the right side lean out
      }
    );
  
    gsap.fromTo(
      textBox,
      {
        opacity: 0,
        y: -20,
      },
      {
        opacity: 1,
        y: 0,
        scrollTrigger: {
          trigger: ".scroll-section",
          start: "top 150%", // Start when the top of the container hits the middle of the viewport
          end: "bottom top", // End when the bottom of the container hits the top of the viewport
          scrub: true, // Smooth animation
        },
      }
    );
  }, []);
  
  
  
  
  
    

  return (
    <div className="AboutScreen">
      <div className="aboutintro-bannner">
        <div className="container-fluid">
          <div className="row">
            <div className="col intro-about p-0">
              <div className="about-banner-img">
                <img
                  src="https://api.listudiosl.com/wp-content/uploads/2024/07/Banner-1.png"
                  alt="about-intro-img"
                  className="about-intro-banner"
                  width="100%"
                  height="auto"
                />
              </div>
              <div className="about-text-prev">
                <h2 className="white">For 16 Years</h2>
                <h2 className="white">We have built</h2>
                <h2 className="white">numerous  brand stories</h2>
                <h2 className="white">that create value, engagement </h2>
                <h2 className="white">and foster loyalty</h2>
               
              </div>

              <div className="about-intro-bottom-text">
                <h2 className="roboto-light white text-center about-h2">
                  Create <span className="red roboto-bold">purpose-based experiences</span>
                  <br />
                  between <span className="red roboto-bold">brands</span> and{" "}
                  <span className="red roboto-bold">consumers</span> that make a
                  difference in<br />
                  people’s lives, communities, and our planet
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="integrity">
        <div className="container-fluid">
          <div className="row row-cols-5">
            <div className="col bg-col-1 hover-box p-0">
            <img src="https://api.listudiosl.com/wp-content/uploads/2024/07/1.png" alt='about-img-in' className='about-img-in' width='100%' loading="lazy" />
              <div className="text-box">
                <h3 className="roboto-thin white">Integrity</h3>
              </div>
              <div className="about-text-description">
                <p className="roboto-light Tone-Down Tone-Down-Text">
                  We strive for excellence and learn from our mistakes.
                </p>
              </div>
            </div>
            <div className="col bg-col-2 hover-box p-0">
            <img src="https://api.listudiosl.com/wp-content/uploads/2024/07/2.png" alt='about-img-in' className='about-img-in' width='100%' loading="lazy" />
              <div className="text-box">
                <h3 className="roboto-thin white">Selective</h3>
              </div>
              <div className="about-text-description">
                <p className="roboto-light Tone-Down Tone-Down-Text">
                  We strive for excellence and learn from our mistakes.
                </p>
              </div>
            </div>
            <div className="col bg-col-3 hover-box p-0">
            <img src="https://api.listudiosl.com/wp-content/uploads/2024/07/3.png" alt='about-img-in' className='about-img-in' width='100%' loading="lazy" />
              <div className="text-box">
                <h3 className="roboto-thin white">Authentic</h3>
              </div>
              <div className="about-text-description">
                <p className="roboto-light Tone-Down Tone-Down-Text">
                  We strive for excellence and learn from our mistakes.
                </p>
              </div>
            </div>
            <div className="col bg-col-4 hover-box p-0">
            <img src="https://api.listudiosl.com/wp-content/uploads/2024/07/4-1.png" alt='about-img-in' className='about-img-in' width='100%' loading="lazy" />
              <div className="text-box">
                <h3 className="roboto-thin white">Empowering</h3>
              </div>
              <div className="about-text-description">
                <p className="roboto-light Tone-Down Tone-Down-Text">
                  We strive for excellence and learn from our mistakes.
                </p>
              </div>
            </div>
            <div className="col bg-col-5 hover-box p-0">
            <img src="https://api.listudiosl.com/wp-content/uploads/2024/07/5.png" alt='about-img-in' className='about-img-in' width='100%' loading="lazy" />
              <div className="text-box">
                <h3 className="roboto-thin white">Giving Back</h3>
              </div>
              <div className="about-text-description">
                <p className="roboto-light Tone-Down Tone-Down-Text">
                  We strive for excellence and learn from our mistakes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about-faq">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 p-0">
              <div className="container-faq">
                <div className="item">
                  <div className="item-header">
                    <div className="title">
                      <h2 className="roboto-light white">What we do?</h2>
                    </div>
                  </div>
                  <div className="item-body">
                    <div>
                      <p className="roboto-regular Tone-Down b1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="item-header">
                    <div className="title">
                      <h2 className="roboto-light white">What we do?</h2>
                    </div>
                  </div>
                  <div className="item-body">
                    <div>
                      <p className="roboto-regular Tone-Down b1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </p>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="item-header">
                    <div className="title">
                      <h2 className="roboto-light white">What we do?</h2>
                    </div>
                  </div>
                  <div className="item-body">
                    <div>
                      <p className="roboto-regular Tone-Down b1">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.<br></br> Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="zoom-out-section gap-row">
        <div className="container-fluid">
        <div className="row">
        <div className="scroll-section">
      <div className="row m-0">
        <div className="col-md-12 image-col p-0">

        
          <img
            src="https://api.listudiosl.com/wp-content/uploads/2024/08/Team-1.jpg"
            alt="Zoomable"
            className="zoom-image"
            loading="lazy"
          />
        </div>
        <div className="col-md-12 text-col">
          <div className="zoom-text-box">
          <h1 className="white roboto-regular about-h1"><span className="roboto-black">Grow your career </span> at the<br></br> heart of change</h1>
          <p className="roboto-regular white b1"> It's your to shine. bring your ingenuity, curioslity and big ideas.</p>
          <button className='roboto-medium header-text-style white pushable'>
                       <span className="shadow"></span>
                       <span className="edge"></span>
                       <span className="front header-text-style">Join us</span>
                    </button>
          </div>
        </div>
      </div>
    </div>
        </div>
        </div>
      </div>

      <div className="Testimonil-page section-gap-new">
        
        <div className="container-fluid">
          <div className="row gap-row">
            <div className="col-md-12">
              <h2 className="roboto-light Dark-Blue">Hear from our <span className=" Dark-Blue roboto-black">clients</span></h2>
            </div>
            <div className="col-md-12 p-0">
             <div className="testimonial-slider">
             <Swiper
        slidesPerView={'auto'}
        navigation={true}
        spaceBetween={30}
        pagination={{
          type: 'fraction',
        }}
        
        modules={[Pagination,Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>

          <div className="container-fuild">
            <div className="row">
          
              <div className="col-md-7">
                  <div className="testi-video">
                    <h3 className="roboto-light Gray text-left">“Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.”</h3>
                    <div className="name-text-box">
                      <p className="roboto-medium b1 Dark-Blue p-0 m-0">James Freeman</p>
                      <p className="b2 roboto-light Gray m-0 p-0">CEO, Company Name</p>
                    </div>
                  </div>
              </div>

              <div className="col-md-5">
                 <div className="texti-box">
                      <img src="https://api.listudiosl.com/wp-content/uploads/2024/08/Group-10526.png" alt="" width={983} height={440} loading="lazy" />
                 </div>
              </div>
            </div>
          </div>

        </SwiperSlide>
        <SwiperSlide>
        <div className="container-fuild">
            <div className="row">
          
            <div className="col-md-7">
                  <div className="testi-video">
                    <h3 className="roboto-light Gray text-left">“Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.”</h3>
                    <div className="name-text-box">
                      <p className="roboto-medium b1 Dark-Blue p-0 m-0">James Freeman</p>
                      <p className="b2 roboto-light Gray m-0 p-0">CEO, Company Name</p>
                    </div>
                  </div>
              </div>

              <div className="col-md-5">
                 <div className="texti-box">
                      <img src="https://api.listudiosl.com/wp-content/uploads/2024/08/Group-10526.png" alt="" width={983} height={440} loading="lazy" />
                 </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        
        
      </Swiper>
            
             </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutScreen;
